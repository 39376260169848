// src/Services.js
import React, { useEffect} from 'react';
import '../CSS/ServicesPage.css';
import Domes from '../img/Electrical-Contractor-Domestic-work.jpg'
import Comm from '../img/Electrical-Contractors-Commercial-Work.jpg'
import EVCharge from '../img/Something-electrical-vehicle-chargers.jpg'
import Solar from '../img/inject-blog-service_1727971526746.png'
import ContactForm from '../Sections/ContactForm';

const ServiceCard = ({ image, title, description, features, link }) => {
    return (
        <div className="service-card-unique">
            <img src={image} alt={title} />
            <div className="content-container">
                <div className="content">
                    <h3>{title}</h3>
                    <p>{description}</p>
                    {link && <a href={link} className="learn-more-btn-unique">Learn More</a>}
                </div>
                {features && features.length > 0 && (
                    <div className="features-list">
                        <ul>
                            {features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

const Services = () => {

    useEffect(() => {
        const sectionToAnimate = document.querySelector('.header-unique');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        if (sectionToAnimate) {
            observer.observe(sectionToAnimate);
        }

        // Clean-up function
        return () => {
            if (sectionToAnimate) {
                observer.unobserve(sectionToAnimate);
            }
        };
    }, []);



    return (
        <div className="services-page-unique">
            <section className="header-unique">
                <h1>Electrical Solutions</h1>
                <p>We Provide Everything for Commercial and Domestic, Including Solar PV & EV Chargers.</p>
            </section>

            <section className="services-intro-unique">
                <h2>Services</h2>
                <p>
                    At FGC, we are dedicated to providing top-tier electrical services to meet all your needs. For homeowners, we specialize in electrical installations for new builds, comprehensive rewires to modernize your existing systems, and custom alterations to fit your evolving lifestyle. In the commercial sector, we offer tailored electrical solutions for a diverse range of businesses including shops, gyms, and office spaces, ensuring your operations run smoothly with optimal electrical performance. Additionally, we are at the forefront of sustainable energy solutions, offering expert installation of Solar PV systems and EV chargers to enhance your energy efficiency and reduce environmental impact. Trust us to power your projects with precision and professionalism.
                </p>
            </section>

            <section className="services-unique">
                <ServiceCard
                    image={Domes}
                    title="Domestic Services"
                    description="At FGC, we offer expert electrical solutions for your home. Whether you're building a new house, in need of a complete rewire, or planning custom alterations, our experienced team is equipped to handle all your residential electrical needs with efficiency and precision."
                    features={["Security Lighting", "Heating Systems", "LED Downlights", "House Rewiring", "Electrical Showers", "Fuse Board Upgrade", "Test Certification"]}
                />
                <ServiceCard
                    image={Comm}
                    title="Commercial Services"
                    description="FGC provides specialized electrical services for commercial spaces, including shops, gyms, and businesses. From routine maintenance to complex installations, our professional team ensures your facilities are equipped with reliable and efficient electrical systems tailored to your specific requirements."
                    features={[
                        "LED Lighting Installation", "Gym Fit Outs", "Fixed Wire Testing", "Cable and Tray Trunking", "UPS & Stand By Generators Installation", "Office Fit Outs", "Restaurant/Hotel Fit Outs", "ETCI Periodic Inspections", "Thermal Imaging", "Emergency Lighting Testing", "IT Structured Cables", "Occupancy Detectors", "Fire Alarm Systems", "Shop Fit Outs", "MV & LV Switchgear Lighting", "Portable Appliance Testing", "LED Retro Fits", "Electrical Maintenance Work", "UPS Installations", "Cat 6 & 6 Installations"
                    ]}
                />
                <ServiceCard
                    image={EVCharge}
                    title="EV Chargers"
                    description="At FGC, we are committed to advancing sustainable energy solutions by offering expert installation of EV chargers. Tailored to meet the needs of both residential and commercial clients, our services ensure that your property is equipped for the future of transportation, providing reliable and convenient charging solutions."
                    link="/evchargers"
                />
                <ServiceCard
                    image={Solar}
                    title="Solar PV"
                    description="FGC specializes in the installation of Solar PV systems, empowering your home or business with clean, renewable energy. Our skilled team ensures a seamless integration of solar technology to optimize energy efficiency and reduce your carbon footprint, making your property a beacon of sustainability."
                    link="/solar"
                />
            </section>

            <ContactForm />
        </div>
       
        
    );
};

export default Services;