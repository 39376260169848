// src/ContactForm.js
import React, { useRef } from 'react';
import '../CSS/ContacForm.css';
import axios from 'axios';
const ContactForm = () => {
    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();

        if (!form.current) {
            console.error('Form reference is not available.');
            return;
        }

        try {
            const formData = new FormData(form.current);
            const data = {
                email: formData.get('email'),
                first_name: formData.get('first-name'),
                last_name: formData.get('last-name'),
                phone: formData.get('phone'),
                job_description: formData.get('job-description'),
            };

            const response = await axios.post('https://backendforf-g.onrender.com/send-email', data);
            console.log('Email sent successfully:', response);

            //Reset form once send is hit 
        } catch (error) {
            console.error('Error in sending email:', error);
        }
    };

    return (
        <section className="contact">
            <h2>Contact Us</h2>
            <p>Fill out the form and We'll get back to you as soon as possible!</p>
            <form ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                    <label htmlFor="first-name">First Name</label>
                    <input type="text" id="first-name" name="first-name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="last-name">Last Name</label>
                    <input type="text" id="last-name" name="last-name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input type="tel" id="phone" name="phone" />
                </div>
                <div className="form-group">
                    <label htmlFor="job-description">Job Description</label>
                    <textarea id="job-description" name="job-description" required></textarea>
                </div>
                <button type="submit">Submit</button>
            </form>
        </section>
    );
}

export default ContactForm;
