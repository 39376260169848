import { AnimatePresence } from 'framer-motion';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import SolarPV from './pages/SolarPV';
import EVChargers from './pages/EVChargers';
import NotFound from './pages/NotFound';
import ErrorBoundary from './pages/ErrorBoundary';
import Navbar from './Sections/NavBar';
import Header from './Sections/Header';
import Footer from './Sections/Footer';
import CookieConsent from 'react-cookie-consent';
import PrivacyPolicy from './Sections/PrivacyPolicy';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Navbar />
        <Header />
        <AnimatedRoutes />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          enableDeclineButton
          cookieName="fgc-site-consent"
          style={{ background: "#2B373B", color: "#fff" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          declineButtonStyle={{ color: "#4e503b", fontSize: "13px", background: "#f0a500" }}
          expires={150}
        >
          This website uses cookies to ensure you get the best experience on our website.{" "}
          <a href="/privacy-policy" style={{ color: "#f0a500", textDecoration: "underline" }}>Learn more</a>
        </CookieConsent>
      </ErrorBoundary>
    </Router>
  );
}

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/solar" element={<SolarPV />} />
        <Route path="/evchargers" element={<EVChargers />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
