// src/Services.js
import React, { useEffect } from 'react';
import SolarPV from '../img/work2.jpg';
import Domestic from '../img/work1.jpg';
import EV from '../img/evcharger.jpeg';
import Commercial from '../img/inject-blog-service_1727971526235.png'
import '../CSS/ServiceSection.css'
import { useNavigate } from 'react-router-dom';
const Services = () => {

    const navigate = useNavigate();


    useEffect(() => {
        const services = document.querySelectorAll('.service');
    
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
              observer.unobserve(entry.target);
            }
          });
        }, {
          threshold: 0.1
        });
    
        services.forEach(service => {
          observer.observe(service);
        });
      }, []);

      const handleReadMore = () => {
        navigate('/services');
      }


    return (
        <section class="services">
        <div class="service">
            <img src={Domestic} alt="Domestic Services" className="service-img"/>
            <div class="service-content">
                <h2>01</h2>
                <h3>Domestic Services</h3>
                <p>At Fagan & Greene we specialize in all aspects of domestic electrical services from new builds, rewires, extensions, heating systems, fuseboard upgrades, periodic inspections, we have you covered.</p>
                <button onClick={handleReadMore}>Read More</button>
            </div>
        </div>
        <div class="service">
            <img src={Commercial} alt="Commercial Services" className="service-img"/>
            <div class="service-content">
                <h2>02</h2>
                <h3>Commercial Services</h3>
                <p>Our Commercial electrical services gave us vast experience in which has come from continuous work in hotels, gyms, restaurants, fast food outlets, shops, barber shops etc. We can work to your needs if closure is not possible or downtime is needed.</p>
                <button onClick={handleReadMore}>Read More</button>
            </div>
        </div>
        <div class="service">
            <img src={EV} alt="Electrical Vehicle Chargers" className="service-img"/>
            <div class="service-content">
                <h2>03</h2>
                <h3>Electrical Vehicle Chargers</h3>
                <p>At Fagan & Greene, we are here to support the future. That's why we are the right choice for installing EV charge points at your business or home.</p>
                <button onClick={handleReadMore}>Read More</button>
            </div>
        </div>
        <div class="service">
            <img src={SolarPV} alt="Solar PV" className="service-img"/>
            <div class="service-content">
                <h2>04</h2>
                <h3>Solar PV</h3>
                <p>Our Commercial electrical services gave us vast experience in which has come from continuous work in hotels, gyms, restaurants, fast food outlets, shops, barber shops etc. We can work to your needs if closure is not possible or downtime is needed.</p>
                <button onClick={handleReadMore}>Read More</button>
            </div>
        </div>
        </section>
    );
}

export default Services;
