import React from "react";

const PrivacyPolicy = () => (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Welcome to Fagan & Greene Contractors' Privacy Policy. We are committed to safeguarding the privacy of our website visitors. This policy explains how we handle any personal information collected through cookies and how you can manage your preferences.</p>
      
      <h2>1. Cookies</h2>
      <p>Our website uses cookies to enhance your experience. You can manage your preferences by adjusting your browser settings.</p>
  
      <h2>2. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, you can contact us at:</p>
      <p>Email: <a href="mailto:info.fgcontractors@gmail.com">info.fgcontractors@gmail.com</a></p>
      <p>Phone: 087 407 7240</p>
    </div>
);

export default PrivacyPolicy;


