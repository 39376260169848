import React from 'react';
import logo from '../img/logo.JPEG'
import safe from '../img/safe-electric.png'

const Header = () => (
    <header className='header'>
    <div className='logo'>
        <img src={logo} alt='Fagan & Greene Logo'/>
    </div>
    <div className="safe-electric">
        <img src={safe} alt="Safe Electric Logo" />
    </div>
</header>
);

export default Header;