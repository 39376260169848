import React from 'react';
import Intro from '../Sections/Intro';
import Services from '../Sections/Services';
import ContactForm from '../Sections/ContactForm';
import Gallery from '../Sections/gallery';
const Home = () => {
  return (
    <div>
      <Intro />
      <Services />
      <Gallery />
      <ContactForm/>
    </div>
  );
};

export default Home;
