import React, { useState } from "react";
import '../CSS/Gallery.css';
import work3 from '../img/work3.jpg';
import work5 from '../img/work5.jpg';
import work6 from '../img/work6.jpg';
import work7 from '../img/work7.jpg';
import work8 from '../img/work8.jpg';
import light from '../img/lightShade.png';
import work9 from '../img/work9.jpg';
import work10 from '../img/work10.jpg';
import charger from '../img/evChargerPhoto.jpg';

const Gallery = () => {

    const [slideIndex, setSlideIndex] = useState(0);
    const images = [
        work3,
        work5,
        work6,
        work7,
        light,
        work9,
        work10,
        charger,
    ];

    const plusSlides = (n) => {
        setSlideIndex((prevIndex) => (prevIndex + n + images.length) % images.length);

    };

    const currentSlide = (n) => {
        setSlideIndex(n);
    };

    return(
        <div className="gallery-section">
            <h2 className="gallery-title">Our Completed Work</h2>
            <div className="slideshow-container">
                {images.map((image, index) => (
                    <div key={index} className={`mySlides ${index === slideIndex ? 'active' : ''}`}>
                        <img src={image} alt={`Project ${index + 1}`} style={{ width: '100%' }} />
                    </div>
                ))}
                <a className="prev" onClick={() => plusSlides(-1)}>prev</a>
                <a className="next" onClick={() => plusSlides(1)}>next</a>
            </div>
            <div className="dot-container">
                {images.map((_, index) => (
                    <span key={index} className={`dot ${index === slideIndex ? 'active' : ''}`} onClick={() => currentSlide(index)}></span>
                
                ))}
            </div>
        </div>
    );

};

export default Gallery;