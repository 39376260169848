// src/Intro.js
import React from 'react';
import van from '../img/van.JPEG'
import '../App.css';

const Intro = () => (
    <section className="intro">
        <h1>Fagan & Greene</h1>
        <p>With over 20 years of combined experience in the domestic, commercial, and industrial sectors, Fagan & Greene Electrical Contractors have established themselves as one of the most reliable and trusted electrical contractors in the region. Founded in 2022, we are based in the South Meath area but proudly serve much of Leinster. Despite being a newly formed company, our commitment to reliability and trustworthiness has quickly helped us build a loyal client base across all our service areas, including maintenance work. We are fully insured and registered with Safe Electric. If you're seeking dependable and professional electrical services, don't hesitate to contact Fagan & Greene Electrical Contractors.</p>
        <img src={van} alt="Fagan & Greene Van" className="van-image" />
    </section>
);

export default Intro;
