import React, {useEffect} from 'react';
import '../CSS/EV.css';
import { FaLightbulb, FaLeaf, FaCar } from 'react-icons/fa';
import EVCar from '../img/inject-blog-service_1727971526201.png'
import ContactForm from '../Sections/ContactForm';

const EVChargersPage = () => {

  useEffect(() => {
    console.log("JavaScript is running!");

    const items = document.querySelectorAll('.why-item');

    if (items.length === 0) {
        console.log("No elements found with the class 'why-item'"); // Debug log for missing elements
    } else {
        console.log(`Found ${items.length} elements with the class 'why-item'`);
    }

    const observerOptions = {
        root: null,
        threshold: 0.2, // Trigger when 20% of the element is in view
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                console.log('Element is in view:', entry.target); // Debug log to check if in view
                entry.target.classList.add('visible'); // Add the 'visible' class to trigger animation
                observer.unobserve(entry.target); // Stop observing once the animation is triggered
            }
        });
    }, observerOptions);

    items.forEach(item => {
        observer.observe(item);
        console.log('Observing:', item); // Debug log to see which elements are being observed
    });

}, []); // Empty dependency array means this will run once when the component mounts
 


  return (
    <div className="ev-chargers-page">
      {/* EV Chargers Overview Section */}
      <section className="ev-overview">
            <img src={EVCar} alt="EV Chargers" className="ev-image" />
            <div className="overlay-text">
                <h2>EV Chargers</h2>
                <p>At Fagan & Greene Contractors, we're all about sustainability. Helping you with installations of Electric Vehicle, Domestic or Commercial.</p>
            </div>
        </section>

  {/* Benefits & Features Section */}
<section className="benefits-features-section">
  <h2 className="benefits-heading">Why Choose Electric</h2>
  <div className="benefits-features">
    <div className="benefit-item">
      <FaLightbulb className="icon" /> {/* Icon at the top */}
      <h3>Cost Efficient</h3>
      <p>Over the long term, electric cars can be cheaper to operate and maintain than gasoline or diesel cars. Electricity is generally cheaper than gasoline or diesel on a per-mile basis, and electric vehicles have fewer moving parts, reducing maintenance costs.</p>
    </div>
    <div className="benefit-item">
      <FaLeaf className="icon" /> {/* Icon at the top */}
      <h3>Environmentally Friendly</h3>
      <p>Electric cars produce zero tailpipe emissions, reducing air pollution and greenhouse gas emissions compared to traditional internal combustion engine vehicles. This helps in combating climate change and improving air quality, especially in urban areas.</p>
    </div>
    <div className="benefit-item">
      <FaCar className="icon" /> {/* Icon at the top */}
      <h3>Performance & Convenience</h3>
      <p>Electric cars offer smooth acceleration and a quieter ride compared to traditional cars. They also require less frequent maintenance (no oil changes, for example) and can be charged at home overnight, offering convenience and flexibility in refueling compared to visiting gas stations.</p>
    </div>
  </div>
</section>


      {/* Why Choose Us Section */}
      <section className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <div className="why-item">
          <h3>Experienced & Certified</h3>
          <p>With extensive experience in the installation of electric vehicle chargers, our certified team ensures that every project meets the highest safety and quality standards.</p>
        </div>
        <div className="why-item">
          <h3>Consultations</h3>
          <p>At Fagan & Greene, we are dedicated to providing efficient, reliable, and tailored solutions to meet the unique needs of each customer, facilitating a seamless transition to electric mobility.</p>
        </div>
        <div className="why-item">
          <h3>Quality and Safety</h3>
          <p>At Fagan & Greene, our commitment to quality and safety is at the forefront of every installation we undertake. We utilize the best materials and adhere to strict safety protocols, ensuring that each electric vehicle charger is installed with precision and care, providing peace of mind for our clients.</p>
        </div>
      </section>

        <ContactForm /> 
    </div>
  );
};

export default EVChargersPage;
