// src/Footer.js
import React from 'react';
import logo from '../img/logo.JPEG'
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';

const Footer = () => (
    <footer className="footer">
    <div className="footer-logo">
            <img src={logo} alt="Fagan & Greene Logo"/>
            <div className="footer-logo-text">
                <p>Fagan & Greene Contractors</p>
                <div className="underline"></div>
            </div>
        </div>
        <div className="footer-info">
            <div className="contact-item">
                <FaMapMarkerAlt />
                <span>South Meath, Ireland</span>
            </div>
            <div className="contact-item">
                <FaEnvelope />
                <span><a href="mailto:info.fgcontractors@gmail.com">info.fgcontractors@gmail.com</a></span>
            </div>
            <div className="contact-item">
                <FaPhone />
                <span><a href="tel:+353874077240">087 407 7240</a></span>
            </div>
        </div>
</footer>
);

export default Footer;
