import React, { useState } from 'react';
import '../CSS/NavBar.css'; // Import the CSS file

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button className="navbar-toggle" onClick={toggleNavbar}>
        ☰
      </button>
      <nav className={`navbar ${isOpen ? 'navbar-open' : ''}`}>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/evchargers">EV Chargers</a></li>
          <li><a href="/solar">Solar PV</a></li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
